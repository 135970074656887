<template>
  <div>
    <!--Top Page Image Viewer & Contact Methods-->
    <top-page />

    <!--Main Page Outer Wrapper-->
    <v-container
      fluid
      grid-list-md
    >
      <v-layout
        row
        wrap
        align-start
      >
        <v-flex
          xs12
          class="pa-0"
        >
          <!--Headline-->
          <v-card
            flat
            class="pa-0 ma-0"
            color="white"
          >
            <v-img
              contain
              max-height="200px"
              src="./img/logo_login.png"
            />
            <div class="display-1 text-xs-center font-weight-bold">
              {{ indexHeadline }}
            </div>

            <!--Sub Headline-->
            <v-flex
              xs12
              class="pb-4"
            >
              <div class="title text-xs-center font-weight-medium">
                {{ indexSubheadline }}
              </div>
            </v-flex>

            <!--Promo Cards-->
            <v-container
              fluid
              grid-list-md
            >
              <v-layout
                row
                wrap
                align-start
                fill-height
              >
                <v-flex
                  v-for="(promo,ii) in promos"
                  :key="ii"
                  class="text-xs-center"
                  xs12
                  md3
                >
                  <v-card
                    class="pa-2"
                    flat
                  >
                    <v-img
                      contain
                      max-height="60px"
                      :src="promo.src"
                    />
                    <v-card-title class="justify-center">
                      <h2 class="font-weight-medium secondary--text">
                        {{ promo.title }}
                      </h2>
                    </v-card-title>
                    <ul>
                      <li
                        v-for="(bullet,iii) in promo.bullets"
                        :key="iii"
                        class="grey--text text--darken-3 subtitle-1 text-xs-left bulletitem"
                      >
                        {{ bullet.text }}
                      </li>
                    </ul>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TopPage from '@/components/TopPage';

export default {
  name: 'Index',
  computed: {
    ...mapState({
      usersStore: (state) => state.usersStore,
    }),
  },
  components: {
    TopPage,
  },
  props: {
    source: String,
  },
  data() {
    return {
      indexHeadline: 'Industry Leader In Contract Decorating',
      indexSubheadline: 'Our streamlined operation allows us to handle both small and large orders with care, quality and promptness. The same standards that have been a staple of our business for years.',
      indexBottomText: 'IDEX INTERNATIONAL PROVIDES CONTRACT EMBROIDERY, SCREEN PRINTING, TO THE ASI, PPAI, SCREEN PRINTING, EMBROIDERY AND TRADE INDUSTRY ON CORPORATE & PERFORMANCE APPAREL, T-SHIRTS, HATS, BAGS AND ACCESSORIES.',
      promos: [
        {
          src: './img/promoembroidery.png',
          title: 'EMBROIDERY',
          bullets: [
            {
            	text: '150 OPERATING SEWING HEADS',
            },
	          {
		          text: '17 HOURS OF PRODUCTION DAILY',
	          },
	          {
		          text: '15 THREAD COLOR CAPABILITY',
	          },
	          {
		          text: 'FREE DIGITIZING ON ORDERS OF 48PCS OR MORE',
	          },
	          {
		          text: 'IN-HOUSE DIGITIZING DEPT.',
	          },
	          {
		          text: 'PERSONALIZATION/NAMES',
	          },
	          {
		          text: 'QUALITY CONTROL DEPARTMENT',
	          },
          ],
        },
	      {
		      src: './img/promoscreenprint.png',
		      title: 'SCREEN PRINTING',
		      bullets: [
			      {
				      text: '7 SCREEN PRINTING PRESSES',
			      },
			      {
				      text: '17 HOURS OF PRODUCTION DAILY',
			      },
			      {
				      text: 'SPOT PRINTING',
			      },
			      {
				      text: 'SIMULATED PROCESS PRINTING',
			      },
			      {
				      text: '4 COLOR PROCESS PRINTING',
			      },
			      {
				      text: 'FULL IN-HOUSE ART DEPARTMENT',
			      },
			      {
				      text: 'QUALITY CONTROL DEPARTMENT',
			      },
		      ],
	      },
	      {
		      src: './img/promofreight.png',
		      title: 'FREIGHT PROGRAMS',
		      bullets: [
			      {
				      text: 'FREE INBOUND FREIGHT FROM SANMAR AND ALPHA/BRODER',
			      },
			      {
				      text: 'NO MINIMUMS FOR INBOUND FREIGHT OFFER',
			      },
			      {
				      text: '$.30 FLAT OUTBOUND GROUND SHIPPING FOR T-SHIRTS ACROSS THE U.S.',
			      },
			      {
				      text: '36 PC MINIMUM FOR OUTBOUND OFFER',
			      },
		      ],
	      },
	      {
		      src: './img/promohat.png',
		      title: 'KC HAT PROGRAM',
		      bullets: [
			      {
				      text: 'ONE-STOP SHOPPING',
			      },
			      {
				      text: '10,000 STITCHES INCLUDED',
			      },
			      {
				      text: 'LOW 24 PC MINIMUM',
			      },
		      ],
	      },
      ],
    };
  },
  methods: {
    /* ---------- Login ----------*/
    something() {

    },
  },
};
</script>

<style scoped>
.bulletitem{
  margin-top:10px;
  margin-right:10px;
}
button {
  outline: none;
}
</style>
